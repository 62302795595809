import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';

export default function Equipa() {

    const carouselImage = require('../img/CarouselBg.jpg');
    const bkgImgHome = {
        backgroundImage: `url(${carouselImage})`,
        backgroundSize: 'cover',
    };

    const media = {
        margin: "auto"
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                }}
            >
                <div style={bkgImgHome} className="page-header">
                    <Typography variant="h6" gutterBottom component="div">
                        A Nossa Equipa
                    </Typography>
                </div>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='DraAnaPriscila.jpg'
                                    alt='Drª Ana Priscila'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Drª Ana Priscila Lemos
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Médica de Clínica Geral
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Especialista em Medicina de Família e Comunidade pela Sociedade Brasileira de Medicina de Família e Comunidade</li>
                                            <li>Supervisora do Programa Mais Médicos para o Brasil do Ministério da Saúde</li>
                                            <li>Professora na Universidade Potiguar</li>
                                            <li>Atuação em saúde infantil, da mulher e do idoso</li>
                                            <li>Consulta de cessação tabágica</li>
                                            <li>Tratamento de dor miofascial por agulhamento seco (dry needling)</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='DraAnaSofia.jpg'
                                    alt='Drª Ana Sofia dos Santos'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Drª Ana Sofia dos Santos
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Terapeuta da Fala
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Formada em Terapia da Fala</li>
                                            <li>Pós-graduada em Motricidade Oro-facial</li>
                                            <li>Comunicação e Linguagem em crianças com Perturbação do Espetro do Autismo</li>
                                            <li>Dificuldades de Alimentação na 1º infância</li>
                                            <li>Disfagias Orofaríngeas</li>
                                            <li>Perturbação dos Sons da Fala e Audição</li>
                                            <li>Terapia Miofuncional</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='DraCatarinaDias.jpg'
                                    alt='Drª Catarina Dias'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Drª Catarina Dias
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Terapeuta Ocupacional
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Licenciatura em Terapia Ocupacional</li>
                                            <li>Mestrado em Terapia Ocupacional com especialização na área de Integração Sensorial na Pediatria</li>
                                            <li>Intervenção clínica em bebés, crianças e jovens</li>
                                            <li>Atraso Global de Desenvolvimento</li>
                                            <li>Perturbação da Comunicação e Linguagem/Autismo</li>
                                            <li>Perturbação do Défice de Atenção e Hiperatividade e Dificuldade de Aprendizagem</li>
                                            <li>Intervenção na Leitura e Escrita</li>
                                            <li>Hidroterapia em Israel com Turvia Stuchiner (President of Israeli Hidroherapy Association)</li>
                                            <li>Método DIRFLOORTIME</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='DraCatarinaSousa.jpg'
                                    alt='Drª Catarina Sousa'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Drª Catarina Sousa
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Especialista em Medicina Tradicional Chinesa
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Licenciada em Medicina Chinesa pela Universidade de Medicina Chinesa Dr. Pedro Choy</li>
                                            <li>Estágio hospitalar na Chengdu University of Tradicional Chinese
                                                Medicine na China</li>
                                            <li>Acupunctura</li>
                                            <li>Acuterapia</li>
                                            <li>Dietética</li>
                                            <li>Fertilidade e Saúde reprodutiva</li>
                                            <li>Fitoterapia</li>
                                            <li>Moxabustão</li>
                                            <li>Tuina</li>
                                            <li>Ventosaterapia</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>
                {/*<Box*/}
                {/*    sx={{*/}
                {/*        display: 'flex',*/}
                {/*        justifyContent: 'top',*/}
                {/*        flexWrap: 'wrap',*/}
                {/*        p: 1,*/}
                {/*        m: 1,*/}
                {/*    }}>*/}
                {/*    <Box*/}
                {/*        sx={{*/}
                {/*            p: 1,*/}
                {/*            m: 1,*/}
                {/*        }}>*/}
                {/*        <Card sx={{ maxWidth: 320 }}>*/}
                {/*            <CardActionArea>*/}
                {/*                <CardMedia*/}
                {/*                    component='img'*/}
                {/*                    image='DraClaraVazMarecos.jpg'*/}
                {/*                    alt='Drª Clara Vaz Marecos'*/}
                {/*                    style={media}*/}
                {/*                />*/}
                {/*                <CardContent>*/}
                {/*                    <Typography gutterBottom variant='h5' component='div'>*/}
                {/*                        Drª Clara Vaz Marecos*/}
                {/*                    </Typography>*/}
                {/*                    <Typography variant="subtitle2" gutterBottom component="div">*/}
                {/*                        Neuropediatra*/}
                {/*                    </Typography>*/}
                {/*                    <Typography variant='body2' color='text.secondary'>*/}
                {/*                        */}{/*<ul>*/}
                {/*                        */}{/*    <li>Licenciatura em Medicina, Faculdade de Medicina de Lisboa</li>*/}
                {/*                        */}{/*    <li>Grau de especialista em Pediatria</li>*/}
                {/*                        */}{/*    <li>Pós Graduada em Neurodesenvolvimento</li>*/}
                {/*                        */}{/*    <li>Curso de Especialização em Neurociências</li>*/}
                {/*                        */}{/*</ul>*/}
                {/*                    </Typography>*/}
                {/*                </CardContent>*/}
                {/*            </CardActionArea>*/}
                {/*        </Card>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='DraDanielaAneis.jpg'
                                    alt='Drª Daniela Anéis'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Drª Daniela Anéis
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Psicóloga
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Licenciada em Psicologia</li>
                                            <li>Mestrado Integrado em Psicologia Clínica – Núcleo de Sistémica</li>
                                            <li>Especialização em Terapia Familiar e de Casal</li>
                                            <li>Especialização em Psicogerontologia</li>
                                            <li>Formação nos programas de orientação parental Anos Incríveis®, Mais Jovem, Mais Família e
                                                Crianças no Meio do Conflito</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='DraFilipaBastos.jpg'
                                    alt='Drª Filipa Bastos'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Drª Filipa Bastos
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Médica Dentista
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Licenciada em Medicina Dentária</li>
                                            <li>Pós Graduada em Odontopediatria</li>
                                            <li>Formação avançada em Toxina Botulinica e Ácido Hialurónico pela Fundação Dr Brian Franks</li>
                                            <li>Curso avançado de Implantologia – Oralklass Education e Dermot McNulty</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='EnfFilipaAzevedo.jpg'
                                    alt='Enf. Filipa Azevedo'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Enf. Filipa Azevedo
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Enfermeira Especialista em Saúde Infantil e Pediatria
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Aconselhamento em aleitamento materno</li>
                                            <li>Enfermagem de família</li>
                                            <li>Alimentação e saúde infantil</li>
                                            <li>Vacinas</li>
                                            <li>Vigilância de peso/estatura/PC do bebé</li>
                                            <li>Introdução de alimentação complementar (tradicional e BLW)</li>
                                            <li>Extracção, recolha e conservação de leite materno</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>
                
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='DraJoyceZanker.jpg'
                                    alt='Drª Joyce Zanker'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Drª Joyce Zanker
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Nutricionista
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Graduada em Dietética e Nutrição</li>
                                            <li>Formadora habilitada</li>
                                            <li>Em Mestrado na Faculdade de Medicina de Lisboa no Curso de Nutrição Clínica</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='DrMarceloBaptista.jpg'
                                    alt='Dr Marcelo Baptista'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Dr Marcelo Baptista
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Osteopata
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Licenciado em Osteopatia - ESSCVP</li>
                                            <li>Especialização em diagnóstico e tratamento da Articulação Temporomandibular (ATM) - Master</li>
                                            <li>Mestre em Exercício e Saúde - FMH</li>
                                            <li>Performance specialist - EXOS</li>
                                            <li>Corrective exercise specialist - NASM</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='DraSaniRibeiro.png'
                                    alt='Drª Sani Ribeiro'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Drª Sani Ribeiro
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Pediatra
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Pediatria Geral</li>
                                            <li>Pediatria de Desenvolvimento</li>
                                            <li>Pneumologia Pediátrica</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='TaniaPina.jpg'
                                    alt='Tânia Pina'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Tânia Pina
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Administrativa Clínica
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Assistente Dentária</li>
                                            <li>Assistente Dentária em Implantologia</li>
                                            <li>Fotografia em Medicina Dentária</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='DrTiagoGabriel.jpg'
                                    alt='Dr Tiago Gabriel'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Dr Tiago Gabriel
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Consulta do Viajante
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Médico Especialista em Saúde Pública</li>
                                            <li>Delegado de Saúde</li>
                                            <li>Elemento do Centro de Vacinação Internacional de Leiria</li>
                                            <li>Formado em Medicina de Viagem e de Populações Móveis</li>                                            
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'top',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Card sx={{ maxWidth: 320 }}>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    image='DraVaniaSousa.jpg'
                                    alt='Drª Vânia Sousa'
                                    style={media}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Drª Vânia Sousa
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        Psicóloga
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <ul>
                                            <li>Licenciada em Psicologia</li>
                                            <li>Facilitadora Certificada em Parentalidade Consciente</li>
                                            <li>Consultora de Sono com Ênfase em Apego Seguro</li>
                                            <li>Pós-Graduada em Consulta de Psicologia e Psicoterapia</li>
                                            <li>Mestrado em Psicoterapia e Psicologia Clínica</li>
                                        </ul> 
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}